import React from "react"
import {graphql} from 'gatsby'
import Layout  from "@components/layout"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { BLOCKS } from "@contentful/rich-text-types"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

export default function PostPage({data}) {
  const post = data.contentfulPost;

  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const { gatsbyImageData, description } = node.data.target
        return (
          <GatsbyImage
            className="my-6"
            image={getImage(gatsbyImageData)}
            alt={description}
          />
        )
     }
    }
  }

  return (
    <Layout title={post.title}>
      <section className={`mx-auto max-w-2xl`}>
        <article>
          <header>
          <h1>{post.title}</h1>
          <small className="text-gray-700">{post.published}</small>
          </header>
          {post.body && renderRichText(post.body, options)}</article>
      </section>
    </Layout>
  
  )
}

export const query = graphql`
query MyQuery($slug: String) {
      contentfulPost(slug: { eq: $slug }) {
        slug
        title
        published(formatString: "d/MM/yy")
        body {
          raw
          references {
            ... on ContentfulAsset {
              contentful_id
              __typename
              gatsbyImageData
            }
          }
      }
    }
  }`